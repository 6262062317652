.fieldset {
  border: none;
  animation: 0.3s fadein both;
  will-change: transform, opacity;
  --field-label-width: 70px;
  .field-label {
    font-weight: bold;
    font-size: 22px;
    color: var(--color-theme);
    width: var(--field-label-width);
    min-width: var(--field-label-width);
    max-width: var(--field-label-width);
    overflow: visible;
    white-space: nowrap;
  }
  .field-content {
    // overflow: hidden;
  }
  &.layout-column {
    .field-label {
      margin-bottom: 20px;
    }
  }
  &:nth-of-type(1) {
    animation-delay: 0s;
  }
  &:nth-of-type(2) {
    animation-delay: 0.05s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.1s;
  }
  &:nth-of-type(4) {
    animation-delay: 0.15s;
  }
  &:nth-of-type(5) {
    animation-delay: 0.2s;
  }
}

@keyframes fadein {
  from {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

#custom-manage {
  --color-strong: #333;
  min-height: 100vh;
  .title {
    width: 100px;
    height: 30px;
    // color: white;
    font-size: 22px;
    color: var(--color-strong);
    overflow: visible;
    white-space: nowrap;
  }
  .type-manage-form {
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;

    .create-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-weight: bold;
      font-size: 14px;
      background: var(--color-theme);
      color: white;
      text-transform: uppercase;
      position: relative;

      @media screen and (min-width: 450px) {
        width: 120px;
      }

      &:hover,
      &:focus {
        &::after {
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.2);
          pointer-events: none;
        }
      }
    }
  }
  .custom-type-guide {
    font-size: 14px;
    margin-bottom: 10px;
    color: var(--color-theme);
  }
  .custom-type-list {
    .custom-type-item {
      height: 60px;
      // .type-color {
      //   width: 20px;
      //   height: 20px;
      // }
      .type-icon {
        font-size: 14px;
        margin-right: 10px;
        cursor: pointer;
      }
      .type-name {
        cursor: pointer;
      }
      .color-input {
        position: absolute;
        left: -9999px;
        // visibility: hidden;
      }

      .color-button,
      .delete-button {
        font-size: 14px;
        color: #aaa;
        cursor: pointer;
      }
      .delete-button {
        margin-left: 10px;
      }
    }
  }
}

.form-footer-placeholder {
  height: 100px;
  @media screen and (min-width: 450px) {
    height: 0;
  }
}
.form-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  @media screen and (min-width: 450px) {
    position: relative;
    margin-top: 80px;
  }

  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  animation: 0.2s slideup both 0.2s;
  opacity: 0;
  background: white;
  cursor: pointer;
  user-select: none;
}

.record-submit-footer {
  @media screen and (min-width: 450px) {
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: start;
    flex-direction: row-reverse;
  }
  .create-button,
  .update-button,
  .delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    background: var(--color-theme);
    color: white;
    text-transform: uppercase;
    position: relative;

    @media screen and (min-width: 450px) {
      width: 120px;
    }

    &:hover,
    &:focus {
      &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.2);
        pointer-events: none;
      }
    }
  }
  .delete-button {
    color: var(--color-theme);
    background: white;
    margin-top: 5px;
    animation: 0.2s slideup both 0.3s;
    @media screen and (min-width: 450px) {
      margin-top: 0;
      margin-right: 10px;
      animation: none;
    }
  }
}

@keyframes slideup {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translate(0);
    opacity: 1;
  }
}

.overview {
  font-size: 12px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  line-height: 1.5;
  &.size-compact {
    .part {
      margin-right: 10px;
    }
  }
  &.size-large {
    width: 100%;
    font-size: 14px;
    color: #333;
    .part {
      white-space: nowrap;
      margin-right: 15px;
    }
  }
}

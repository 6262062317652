.option-editor {
  .option {
    text-align: center;
    font-size: 16px;
    margin-left: 10px;
    border: none;
    min-height: 50px;
    min-width: 70px;
    padding: 0 10px;
    cursor: pointer;
    user-select: none;
    &.is-selected {
      color: white;
    }
  }
}

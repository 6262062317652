#profile {
  --color-strong: #82c91e;
  min-height: 100vh;
  .title {
    width: 100px;
    height: 30px;
    // color: white;
    font-size: 22px;
    color: var(--color-strong);
    overflow: visible;
    white-space: nowrap;
  }
  .profile-form {
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;

    .divider {
      color: #eee;
      margin: 20px 0;
    }

    .password-form {
      // margin-top: 30px;
      label {
        // color: #666;
        font-weight: normal;
      }
      .submit-button {
        margin-top: 20px;
      }
    }
  }
}

#home {
  width: 100%;
  min-height: 100vh;
  background-image: url('../../assets/bg_colorful.png');
  .user-name {
    cursor: pointer;
    user-select: none;
    &:hover {
      opacity: 0.8;
    }
  }
  .page-content {
    margin-top: calc((100vh - 500px) / 2);
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    max-width: 400px;
  }
  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-bottom: 10px;

    .action {
      height: 200px;
      color: white;
      text-align: center;
      user-select: none;
      cursor: pointer;
      animation: 0.3s show both;
      will-change: transform, opacity;
      position: relative;
      &:hover,
      &:focus {
        &::after {
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.2);
          pointer-events: none;
        }
      }
      &.eat {
        animation-delay: 0.1s;
      }
      &.sleep {
        animation-delay: 0.15s;
      }
      &.pisspoop {
        animation-delay: 0.2s;
      }
      &.custom {
        animation-delay: 0.15s;
      }
      &.statistics {
        animation-delay: 0.2s;
        grid-column-end: span 2;
      }
    }
  }
  .footer {
    font-size: 16px;
  }
  &.no-animation * {
    animation: none !important;
  }
}

@keyframes show {
  from {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

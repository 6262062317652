.input {
  font-family: inherit;
  font-size: 16px;
  height: 44px;
  padding: 5px 5px;
  width: 100%;
}

label.label {
  display: block;
  color: #eee;
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  line-height: 1.4;
}

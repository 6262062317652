.text-editor {
  width: 100%;
  .text-input {
    width: 100%;
    min-width: 0px;

    font-family: inherit;
    text-align: right;
    font-size: 18px;

    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    padding: 0 5px;
  }
}
